export interface DataType {
  id: string;
  name: string;
  group: string;
  url: string;
  status: string;
  ya: string;
  google: string;
  ya_sqi: number;
  keywords: number;
  links: number;
  sum_budget: number;
}

const getRandomInt = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const groups = ['Американские сайты', 'Европейские сайты', 'Российские сайты'];
const statuses = ['В работе', 'Не запущен'];
const urls = [
  'microsoft.com',
  'yahoo.com',
  'facebook.com',
  'google.com',
  'miami-beach.com',
];

const siteNames = [
  'Microsoft', 'Google', 'Amazon', 'Facebook', 'Twitter',
  'BBC', 'Deutsche Welle', 'Le Monde', 'The Guardian', 'El País',
  'Яндекс', 'Mail.ru', 'РБК', 'Газета.ру', 'Лента.ру',
  'Apple', 'Netflix', 'Instagram', 'LinkedIn', 'Reddit',
  'WhatsApp', 'Snapchat', 'Pinterest', 'YouTube', 'TikTok',
  'Spotify', 'Airbnb', 'Uber', 'Zoom', 'Slack',
  'Salesforce', 'Adobe', 'Tesla', 'Samsung', 'Intel',
  'IBM', 'Oracle', 'Cisco', 'HP', 'Dell',
  'Xiaomi', 'Huawei', 'Sony', 'LG', 'Nokia',
  'Baidu', 'Tencent', 'Alibaba', 'JD.com', 'Weibo',
  'Sina', 'Naver', 'Kakao', 'Line', 'Rakuten',
  'Booking.com', 'Expedia', 'TripAdvisor', 'Skyscanner', 'Trivago',
  'Zillow', 'Realtor', 'Indeed', 'Monster', 'Glassdoor',
  'GitHub', 'GitLab', 'Bitbucket', 'Stack Overflow', 'Medium',
  'Hacker News', 'Quora', 'Wikipedia', 'Wikimedia', 'IMDb',
  'Rotten Tomatoes', 'Metacritic', 'Flickr', 'DeviantArt', 'Behance',
  'Dribbble', 'Vimeo', 'Dailymotion', 'SoundCloud', 'Mixcloud',
  'Kickstarter', 'Indiegogo', 'Patreon', 'GoFundMe', 'Etsy',
  'Shopify', 'Magento', 'BigCommerce', 'WooCommerce', 'Squarespace',
  'Wix', 'WordPress', 'Joomla', 'Drupal', 'Tumblr',
  'Blogger', 'LiveJournal', 'Weebly', 'Angelfire', 'Geocities',
  'Craigslist', 'eBay', 'AliExpress', 'Walmart', 'Target',
  'Costco', 'Best Buy', 'Home Depot', 'Lowe\'s', 'IKEA',
  'Zara', 'H&M', 'Uniqlo', 'Nike', 'Adidas',
  'Puma', 'Under Armour', 'Reebok', 'New Balance', 'Converse',
];


export const dataSource: DataType[] = Array.from({ length: 150 }, (_, i) => {
  const group = groups[i % groups.length];
  const siteName = siteNames[i % siteNames.length];
  return {
    id: (i + 1).toString(),
    name: `${siteName}`,
    group: group,
    url: urls[i % urls.length],
    status: statuses[getRandomInt(0, statuses.length - 1)],
    ya: `${getRandomInt(0, 100)}%`,
    google: `${getRandomInt(0, 100)}%`,
    ya_sqi: getRandomInt(10, 1000),
    keywords: getRandomInt(1, 999),
    links: getRandomInt(1, 10000),
    sum_budget: getRandomInt(1, 999) * 229,
  };
});
