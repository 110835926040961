import React, { useState } from "react";
import {
  Table,
  ConfigProvider,
  Button,
  Select,
  Pagination,
  Spin,
  Modal,
  Input,
} from "antd";
import ruRU from "antd/lib/locale/ru_RU";
import { dataSource, DataType } from "./data";
import "./App.css";

const { Option } = Select;
const { Search } = Input;

// Определяем колонки для таблицы
const columns = [
  {
    title: "Сайт",
    key: "info",
    render: (text: string, record: DataType) => {
      const groupClass = `group-label ${
        record.group === "Американские сайты"
          ? "group-american"
          : record.group === "Европейские сайты"
          ? "group-european"
          : "group-russian"
      }`;

      return (
        <div>
          <strong>{record.name}</strong>
          <br />
          <a
            href={record.url}
            target="_blank"
            rel="noopener noreferrer"
            className="project-url"
          >
            {record.url}
          </a>
          <br />
          <span className={groupClass}>{record.group}</span>
        </div>
      );
    },
  },
  {
    title: "Статус",
    dataIndex: "status",
    key: "status",
    sorter: (a: DataType, b: DataType) => {
      const order: Record<string, number> = { "В работе": 1, "Не запущен": 2 };
      return (order[a.status] || 0) - (order[b.status] || 0);
    },
    render: (status: string) => {
      const statusClass =
        status === "Не запущен" ? "status-not-started" : "status-in-progress";
      return <span className={statusClass}>{status}</span>;
    },
  },
  {
    title: "Яндекс",
    dataIndex: "ya",
    key: "ya",
    sorter: (a: DataType, b: DataType) => {
      const numA = parseFloat(a.ya); // Извлекаем число из строки
      const numB = parseFloat(b.ya);
      return numA - numB;
    },
  },
  {
    title: "Google",
    dataIndex: "google",
    key: "google",
    sorter: (a: DataType, b: DataType) => {
      const numA = parseFloat(a.google); // Извлекаем число из строки
      const numB = parseFloat(b.google);
      return numA - numB;
    },
  },
  {
    title: "ИКС",
    dataIndex: "ya_sqi",
    key: "ya_sqi",
    sorter: (a: DataType, b: DataType) => a.ya_sqi - b.ya_sqi,
  },
  {
    title: "Запросы",
    dataIndex: "keywords",
    key: "keywords",
    sorter: (a: DataType, b: DataType) => a.keywords - b.keywords,
    render: (keywords: number) => (
      <a href={`#keywords-${keywords}`} className="siteUrl">
        {keywords}
      </a>
    ),
  },
  {
    title: "Ссылки",
    dataIndex: "links",
    key: "links",
    sorter: (a: DataType, b: DataType) => a.links - b.links,
    render: (links: number) => (
      <a href={`#links-${links}`} className="siteUrl">
        {links}
      </a>
    ),
  },
  {
    title: "Бюджет",
    dataIndex: "sum_budget",
    key: "sum_budget",
    sorter: (a: DataType, b: DataType) => a.sum_budget - b.sum_budget,
  },
];

const App: React.FC = () => {
  const [selectedAction, setSelectedAction] = useState<string | undefined>(
    undefined
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<string | undefined>(
    undefined
  );
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleApplyAction = () => {
    Modal.info({
      title: "Упс...",
      content: "Прости, Сашуля, я это еще не сделал :(",
      maskClosable: true,
    });
  };

  const handlePageChange = (page: number, size?: number) => {
    setLoading(true);
    setCurrentPage(page);
    setPageSize(size!);
    // Имитация задержки для показа лоадера
    setTimeout(() => setLoading(false), 500);
  };

  const handleGroupChange = (value: string) => {
    setLoading(true); // Включаем индикатор загрузки
    setSelectedGroup(value);
    setCurrentPage(1); // Сбрасываем на первую страницу при изменении фильтра

    setTimeout(() => {
      setLoading(false); // Выключаем индикатор загрузки после задержки
    }, 500); // Задержка в 500 миллисекунд
  };

  const handleSearch = (value: string) => {
    setLoading(true);
    setSearchTerm(value);
    setCurrentPage(1); // Сбрасываем на первую страницу при поиске

    setTimeout(() => {
      setLoading(false);
    }, 500); // Задержка в 500 миллисекунд
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  // Фильтруем данные на основе выбранной группы и поискового запроса
  const filteredData = dataSource.filter((item) => {
    const matchesGroup = selectedGroup ? item.group === selectedGroup : true;
    const matchesSearch = searchTerm
      ? item.name.toLowerCase().includes(searchTerm.toLowerCase())
      : true;
    return matchesGroup && matchesSearch;
  });

  // Определяем данные для текущей страницы
  const paginatedData = filteredData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  // Извлекаем уникальные группы из dataSource для заполнения выпадающего списка
  const uniqueGroups = Array.from(
    new Set(dataSource.map((item) => item.group))
  );

  return (
    <ConfigProvider locale={ruRU}>
      <div className="container">
        <div className="header">
          <h1>Мои сайты</h1>
        </div>
        <div className="control-panel">
          <div className="search-panel">
            <Search
              placeholder="Поиск по названию сайта"
              onSearch={handleSearch}
              style={{ width: 300 }}
              enterButton
              allowClear
            />
            <Select
              placeholder="Выберите группу"
              className="select-group"
              onChange={handleGroupChange}
              value={selectedGroup}
              allowClear
            >
              {uniqueGroups.map((group) => (
                <Option key={group} value={group}>
                  {group}
                </Option>
              ))}
            </Select>
          </div>
          <Button
            type="primary"
            className="add-site-button"
            onClick={handleApplyAction}
          >
            Добавить сайт
          </Button>
        </div>

        <Spin spinning={loading} size="large">
          <Table<DataType>
            dataSource={paginatedData}
            columns={columns}
            rowKey="id"
            rowSelection={rowSelection}
            pagination={false} // Отключаем встроенную пагинацию
          />
        </Spin>
        <div className="table-bottom-container">
          <div>
            <Select
              placeholder="Выберите действие"
              className="select-action"
              onChange={setSelectedAction}
              value={selectedAction}
            >
              <Option value="delete">Проверить позиции в Яндекс</Option>
              <Option value="export">Проверить позиции в Google</Option>
              <Option value="archive">Изменить группу</Option>
              <Option value="delete">Удалить сайты</Option>
            </Select>
            <Button
              type="default"
              onClick={handleApplyAction}
              disabled={!selectedAction}
            >
              Применить
            </Button>
          </div>
          <Pagination
            total={filteredData.length}
            current={currentPage}
            pageSize={pageSize}
            onChange={handlePageChange}
            showSizeChanger
            pageSizeOptions={["10", "20", "50", "100"]}
          />
        </div>
      </div>
    </ConfigProvider>
  );
};

export default App;
